.app-text-blue {
	color: var(--sapphire);
}

.app-bg-primary {
	background-color: var(--blue);
}

.app-bg-secondary {
	background-color: var(--gray);
}

.app-bg-light {
	background-color: var(--light-gray);
}

.app-bg-white {
	background-color: var(--white);
}

.app-bg-active {
	background-color: var(--orange);
}

.app-bg-booking-1 {
	background-color: var(--ruddy-blue);
}

.app-text-primary {
	color: var(--blue);
}

.app-text-secondary {
	color: var(--gray);
}

.app-text-light {
	color: var(--light-gray);
}

.app-text-white {
	color: var(--white);
}

.app-text-active {
	color: var(--orange);
}

.app-font-roboto {
	font-family: 'Roboto', sans-serif;
}

.app-btn-primary {
	background-color: var(--blue);
	color: var(--white);
}

.app-btn-secondary {
	background-color: var(--gray);
	color: var(--white);
}

.app-btn-light {
	background-color: var(--light-gray);
	color: var(--white);
}

.app-btn-white {
	background-color: var(--white);
	color: var(--blue);
}

.app-btn-active {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-primary:hover {
	background-color: var(--light-gray);
	color: var(--white);
}

.app-btn-secondary:hover {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-light:hover {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-white:hover {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-active:hover {
	background-color: var(--blue);
	color: var(--white);
}

.app-btn-primary:focus {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-secondary:focus {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-light:focus {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-white:focus {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-active:focus {
	background-color: var(--blue);
	color: var(--white);
}

.app-btn-primary:active {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-secondary:active {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-light:active {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-white:active {
	background-color: var(--orange);
	color: var(--white);
}

.app-btn-active:active {
	background-color: var(--blue);
	color: var(--white);
}