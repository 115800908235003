:root {
	--blue: #1f5790;
	--white: #fdfefd;
	--gray: #b3b2b2;
	--light-gray: #97c1e8;
	--orange: #faaf97;
	--pudra-blue: #d6e4f0;
	--soft-dark: #2c2c2c;
	--candlelight: #FFECB3;
	--font: 'Roboto', sans-serif;
	--ruddy-blue: hsla(213, 81%, 70%, 1);
	--cornflower-blue: hsla(214, 84%, 66%, 1);
	--french-blue: hsla(207, 87%, 41%, 1);
	--sapphire: hsla(212, 90%, 35%, 1);
	--indigo-dye: hsla(207, 94%, 25%, 1);
	--cocoa-brown: hsla(25, 78%, 52%, 1);
	--dark-sienna: hsla(25, 78%, 42%, 1);
	--upgrade-button: linear-gradient(90deg, hsla(339, 100%, 55%, 1) 0%, hsla(197, 100%, 64%, 1) 100%);
	/* success colors */
	--success: #4caf50;
	--success-light: #81c784;
	--success-dark: #548c2f;
	/* error colors */
	--error: #f44336;
	--error-light: #e57373;
	--error-dark: #d32f2f;
	/* warning colors */
	--warning: #ff9800;
	--warning-light: #ffb74d;
	/* gradient color hsl yap */
	--warning-dark: #f57c00;
	/* info colors */
	--info: #2196f3;
	--info-light: #64b5f6;
	--info-dark: #1976d2;
	/* disabled colors */
	--disabled: #9e9e9e;
	--disabled-light: #bdbdbd;
	--disabled-dark: #616161;
	/* confirmed colors */
	--confirmed: #06d6a0;

}