@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

@import url('./themplate.css');
@import url('./colors.css');
@import url('./app-brand.css');

body {
	background-color: var(--pudra-blue);
	color: var(--soft-dark);
	font-family: 'Roboto', sans-serif;
	/* font-size: 14px; */
	height: 100vh;
	/* line-height: 1.7; */
	overflow-y: scroll;
	font-size: 0.875rem;
	height: 100%;
	line-height: 1.5;
	
}

@media screen and (min-width: 768px) {
	body {
		font-size: 14px;
	}
}

@media screen and (min-width: 992px) {
	body {
		font-size: 15px;
	}
}

@media screen and (min-width: 1200px) {
	body {
		font-size: 16px;
	}
}

@media screen and (min-width: 1600px) {
	body {
		font-size: 18px;
	}
}